import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useUsers() {
  const permissions = ref([]);
  const roles = ref([]);
  const rolePermissions = ref([]);
  const role = ref();
  const router = useRouter();
  const meta = ref([]);
  const getRoles = async (page) => {
    let response = await ApiService.query("roles", page);
    roles.value = response.data.data;
    meta.value = response.data.meta;
  };

  const getPermissions = async () => {
    let response = await ApiService.get("permissions_all");
    permissions.value = response.data.data;
  };

  const getRole = async (id) => {
    let response = await ApiService.get("roles/" + id);

    role.value = response.data.data.role;
    permissions.value = response.data.data.permissions;
    rolePermissions.value = response.data.data.rolePermissions;
  };

  const destroyRole = async (id) => {
    await ApiService.delete("roles/" + id);
  };

  const stoerRole = async (data) => {
    await ApiService.post("roles", data);
    await router.push({ name: "settings.roles.index" });
  };

  const updateRole = async (data) => {
    await ApiService.put("roles/" + data.id, data);
    await router.push({ name: "settings.roles.edit", params: { id: data.id } });
  };

  return {
    roles,
    meta,
    rolePermissions,
    permissions,
    getPermissions,
    getRoles,
    getRole,
    role,
    destroyRole,
    stoerRole,
    updateRole,
  };
}
